var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommend-v2__list mgds-recommend mgds-goodsd",class:{
    'mgds-goodsd__has-more': _vm.showViewMoreIcon && !_vm.status.loadError,
    'mgds-goodsd__is-new': _vm.config.isNew
  },attrs:{"da-expose-code":_vm.componentsCode}},[(_vm.placeholderStatus && !_vm.products.length)?_c('div',{staticClass:"recommend-goods-holder"},_vm._l(((_vm.rowNum * 8)),function(i){return _c('div',{key:i,class:{
        'recommend-goods-holder__container-three': _vm.rowNum === 3,
        'recommend-goods-holder__container': _vm.rowNum === 2,
      }},[_c('div',{class:{
          'recommend-goods-holder__placehold': _vm.rowNum === 2,
          'recommend-goods-holder__placehold-three': _vm.rowNum === 3,
        }}),_vm._v(" "),_c('div',{staticClass:"recommend-goods-holder__bottom-info_one"}),_vm._v(" "),_c('div',{staticClass:"recommend-goods-holder__bottom-info_two"})])}),0):_vm._e(),_vm._v(" "),(_vm.config.waterFallStyle)?[_c('WaterFall',{class:{
        'water-fall__new': _vm.config.isNew
      },attrs:{"items":_vm.products},scopedSlots:_vm._u([{key:"default",fn:function({ item, index: itemIndex }){return [(_vm.surveyQuestion && _vm.surveyQuestion.showPosition == itemIndex)?_c('SurveyQuestion',_vm._b({},'SurveyQuestion',_vm.surveyQuestion,false)):_c('SProductItemV3',_vm._g({key:`${itemIndex}_${item.goods_id}`,ref:`goodsItem_${item.goods_id}`,staticClass:"recommend__goods-item",class:{
            'recommend__goods-item-new': true
          },attrs:{"language":_vm.itemLanguage,"constant-data":_vm.constantData,"item":item,"index":itemIndex,"config":_vm.config.items || {},"column":_vm.rowNum,"one-click-pay-is-taking-effect":_vm.oneClickPayIsTakingEffect},on:{"hook:mounted":_vm.handleItemMounted,"clickItem":_vm.clickItem,"openQuickAdd":_vm.openQuickAdd}},_vm.$listeners))]}}],null,false,3165465430)})]:[_vm._l((_vm.products),function(item,key){return [(!_vm.config.isNew && (key + 1 <= _vm.maxLimitNum))?_c('SProductItem',_vm._g({key:`${key}_${item.goods_id}`,ref:"goodsItem",refInFor:true,staticClass:"recommend__goods-item",class:{
          'recommend__goods-item-three': _vm.rowNum === 3,
          'recommend__goods-item-two': _vm.rowNum === 2
        },attrs:{"language":_vm.itemLanguage,"constant-data":_vm.constantData,"item":item,"index":key,"config":_vm.config.items || {},"column":_vm.rowNum},on:{"hook:mounted":_vm.handleItemMounted,"clickItem":_vm.clickItem,"openQuickAdd":_vm.openQuickAdd}},_vm.$listeners)):_vm._e(),_vm._v(" "),(_vm.config.isNew)?_c('SProductItemV3',_vm._g({key:`${item.goods_id}_${key}`,ref:"goodsItem",refInFor:true,staticClass:"recommend__goods-item",class:{
          'recommend__goods-item-new': true
        },attrs:{"language":_vm.itemLanguage,"constant-data":_vm.constantData,"item":item,"index":key,"config":_vm.config.items || {},"column":_vm.rowNum,"one-click-pay-is-taking-effect":_vm.oneClickPayIsTakingEffect},on:{"hook:mounted":_vm.handleItemMounted,"clickItem":_vm.clickItem,"openQuickAdd":_vm.openQuickAdd}},_vm.$listeners)):_vm._e()]})],_vm._v(" "),(!_vm.placeholderStatus && !_vm.products.length && _vm.isPlaceholderOptimize)?_c('ErrorPage',{attrs:{"language":_vm.language},on:{"reRequest":_vm.reRequest}}):_vm._e(),_vm._v(" "),(!_vm.placeholderStatus && !_vm.products.length && !_vm.isPlaceholderOptimize)?_c('SFeedback',{staticClass:"recommend-empty",attrs:{"content":_vm.language.SHEIN_KEY_PWA_15819}}):_vm._e(),_vm._v(" "),(_vm.showViewMoreIcon)?_c('div',{staticClass:"recommend__show-more",class:{
      'recommend__more-error': _vm.status.loadError
    }},[(_vm.status.loadError)?_c('p',{attrs:{"data-error":"1","da-event-click":_vm.analysis.viewMoreItem && _vm.analysis.viewMoreItem.itemDAEventClickId,"da-event-expose":_vm.analysis.viewMoreItem && _vm.analysis.viewMoreItem.errorDAEventExposeId},on:{"click":_vm.onViewMoreClick}},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_18764)+"\n    ")]):_c('button',{class:{ 'button-preload': _vm.config.viewMorePreLoad },attrs:{"da-event-click":_vm.analysis.viewMoreItem && _vm.analysis.viewMoreItem.itemDAEventClickId,"data-next":_vm.pageNum + 1},on:{"click":_vm.onViewMoreClick}},[_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_15698)+"\n      "),_c('i',{staticClass:"iconfont icon-Product_list_downx"})])]):_vm._e(),_vm._v(" "),(_vm.loadingMore)?_c('s-loading',{style:({
      width: '100%',
    }),attrs:{"show":true}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }